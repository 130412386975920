import {jwtDecode} from 'jwt-decode';
import {CountryType} from "./types/CountryType";
import {RequestType} from "./types/RequestType";
import {OtbJwt} from "./types/OtbJwt";
import {saveAs} from "file-saver";
import {FileType} from "./types/FileType";
import {PassportType} from "./types/PassportType";
import {RegionType} from "./types/RegionType";
import {DistrictType} from "./types/DistrictType";
import {CompanyType} from "./types/CompanyType";
import {DKHAType} from "./types/DKHAType";
import {ContractType, FlatContractDataType, FlatContractType, NewContractType} from "./types/ContractType";
import {EmployeeType} from "./types/EmployeeType";
import {VesselType} from "./types/VesselType";
import {NotificationType} from "./types/NotificationType";
import {DogovorType} from "./types/DogovorType";
import {RequestBlockType} from "./types/RequestBlockType";
import {WaterPublicType} from "./types/WaterPublicType";

export class REST {
    public static BASE: String = process.env.REACT_APP_BASE ?? "";
    public static title: string = "АИС «ТАБ.ДОСМОТР»";

    public static login(username: string, password: string): Promise<OtbJwt> {
        return fetch(this.BASE + "/api/auth", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({username: username, password: password})
        })
            .then((response) => {
                if (response.status === 401) {
                    return response.json()
                        .then((e) => {
                            throw new Error(e['message'])
                        })
                        .catch((e: Error) => {
                            throw new Error(e.message)
                        });
                }
                return response.json();
            })
            .then((token) => {
                const jwt = jwtDecode<OtbJwt>(token?.access_token);
                jwt.user.cert = (jwt.user.cert === undefined ? null : jwt.user.cert);
                sessionStorage.setItem("me", JSON.stringify(jwt.user));
                sessionStorage.setItem("jwt", token?.access_token);
                //localStorage.setItem('token', token);
                return jwt;
            });
    }

    public static logout(): void {
        fetch(this.BASE + "/api/logout", {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .catch((error) => {
                console.error(error);
                return [];
            })
            .finally(() => {
                sessionStorage.removeItem("me");
                sessionStorage.removeItem("jwt");
                window.location.href = '/';
            });
    }

    public static getCountry(): Promise<CountryType[]> {
        return fetch(this.BASE + "/api/cache/country", {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<CountryType[]>) => {
                return data;
            })
            .catch((error) => {
                console.error(error);
                return [];
            })
            .finally();
    }

    public static getRegion(): Promise<RegionType[]> {
        return fetch(this.BASE + "/api/cache/region", {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<RegionType[]>) => {
                return data;
            })
            .catch((error) => {
                console.error(error);
                return [];
            })
            .finally();
    }

    public static getDistrictByRegion(rid: number): Promise<DistrictType[]> {
        return fetch(this.BASE + "/api/cache/district/" + rid, {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<DistrictType[]>) => {
                return data;
            });
    }

    public static getRequest(type: String): Promise<RequestType[]> {
        return fetch(this.BASE + "/api/request?type=" + type, {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<RequestType[]>) => {
                return data;
            })
            .catch((error) => {
                console.error(error);
                return [];
            })
            .finally();
    }

    public static getRequestById(id: string): Promise<RequestType> {
        return fetch(this.BASE + "/api/request/" + id, {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<RequestType>) => {
                return data;
            });
    }

    public static setRequestStatus(id: bigint, status: string): Promise<RequestType> {
        return fetch(this.BASE + "/api/request/status", {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify({id: id, status: status})
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<RequestType>) => {
                return data;
            });
    }

    public static download(f: FileType): void {
        fetch(this.BASE + "/api/file/download?fid=" + f.id, {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.blob();
            })
            .then((blob) => {
                saveAs(blob, f.name);
            });
    }

    public static downloadReport(r: RequestType | null, format: string): void {
        const uid = 1;
        fetch(this.BASE + "/api/report/download." + format + "?uid=" + uid + "&start=0&finish=0&req=" + r?.id, {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.blob();
            })
            .then((blob) => {
                saveAs(blob, "Заявка №" + r?.id + " от " + r?.formattedDate + "." + format);
            });
    }

    public static downloadDailyReport(cid: bigint, start: string, finish: string, format: string): void {
        const uid = 2;
        fetch(this.BASE + "/api/report/download." + format + "?uid=" + uid + "&req=" + cid + "&start=" + start + "&finish=" + finish, {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.blob();
            })
            .then((blob) => {
                saveAs(blob, "Справка по контракту." + format);
            });
    }

    public static downloadZipReport(cid: bigint, contractNumber: string, start: number, finish: number): Promise<any> {
        return fetch(this.BASE + "/api/package/contract/" + cid + ".zip?start=" + start + "&finish=" + finish, {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.blob();
            })
            .then((blob) => {
                saveAs(blob, contractNumber + ".zip");
            });
    }

    /*public static uploadFile(form: FormData): Promise<FileType> {
        return fetch(this.BASE + "/api/file/upload", {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt"),
            },
            body: form
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<FileType>) => {
                return data;
            });
    }*/

    public static createRequest(fields: any): Promise<RequestType> {
        return fetch(this.BASE + "/api/request", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")
            },
            body: JSON.stringify(fields)
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((req) => {
                return req;
            });
    }

    public static createContract(contract: NewContractType): Promise<ContractType> {
        return fetch(this.BASE + "/api/contract", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")
            },
            body: JSON.stringify(contract, (_, v) => typeof v === 'bigint' ? v.toString() : v)
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((req) => {
                return req;
            });
    }

    public static getPassports(): Promise<PassportType[]> {
        return fetch(this.BASE + "/api/passport", {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<PassportType[]>) => {
                return data;
            });
    }

    public static getCompanyByInn(inn: number, useCache: boolean): Promise<CompanyType> {
        return fetch(this.BASE + "/api/company/" + (useCache ? "cache/" : "") + inn.toString(), {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<CompanyType>) => {
                return data;
            });
    }

    public static getVesselByIMO(imo: number): Promise<VesselType[]> {
        return fetch(this.BASE + "/api/cache/vessel/" + imo, {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<VesselType[]>) => {
                return data;
            });
    }

    public static getDKHAByNumber(number: number): Promise<DKHAType | null> {
        return fetch(this.BASE + "/api/cache/dkha/" + number, {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<DKHAType | null>) => {
                return data;
            });
    }

    public static getOperatorContractList(): Promise<ContractType[]> {
        return fetch(this.BASE + "/api/contract/operator", {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<ContractType[]>) => {
                return data;
            });
    }

    public static getExecutorContractList(): Promise<ContractType[]> {
        return fetch(this.BASE + "/api/contract/executor", {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<ContractType[]>) => {
                return data;
            });
    }

    public static getCustomerContractList(): Promise<ContractType[]> {
        return fetch(this.BASE + "/api/contract/customer", {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<ContractType[]>) => {
                return data;
            });
    }

    public static getContractById(id: number): Promise<FlatContractType> {
        return fetch(this.BASE + "/api/contract/" + id, {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<FlatContractType>) => {
                return data;
            });
    }

    public static getContractDataById(cid: number, dkha: number): Promise<FlatContractDataType[]> {
        return fetch(this.BASE + "/api/contract/" + cid + "/" + dkha, {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<FlatContractDataType[]>) => {
                return data;
            });
    }

    public static uploadDailyReport(form: FormData): Promise<bigint> {
        return fetch(this.BASE + "/api/contract/upload", {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt"),
            },
            body: form
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<bigint>) => {
                return data;
            });
    }

    public static getMe(): Promise<EmployeeType> {
        return fetch(this.BASE + "/api/user/", {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<EmployeeType>) => {
                return data;
            });
    }

    public static getSubordinates(): Promise<EmployeeType[]> {
        return fetch(this.BASE + "/api/user/subordinates", {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<EmployeeType[]>) => {
                return data;
            });
    }

    public static changeFinalExecutor(payload: any): Promise<RequestType> {
        return fetch(this.BASE + "/api/request/change-executor", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")
            },
            body: JSON.stringify(payload, (_, v) => typeof v === 'bigint' ? v.toString() : v)
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<RequestType>) => {
                return data;
            });
    }

    public static changePassword(newPassword: string): any {
        return fetch(this.BASE + "/api/user/password", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")
            },
            body: JSON.stringify({password: newPassword})
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
            });
    }

    public static getCompanies(): Promise<CompanyType[]> {
        return fetch(this.BASE + "/api/company", {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<CompanyType[]>) => {
                return data;
            });
    }

    public static getNotifications(type: string): Promise<NotificationType[]> {
        return fetch(this.BASE + "/api/notification?type=" + type, {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<NotificationType[]>) => {
                return data;
            });
    }

    public static registerCompany(company: any): Promise<CompanyType> {
        return fetch(this.BASE + "/api/company", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")
            },
            body: JSON.stringify(company, (_, v) => typeof v === 'bigint' ? v.toString() : v)
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<CompanyType>) => {
                return data;
            });
    }

    public static getDogovors(): Promise<DogovorType[]> {
        return fetch(this.BASE + "/api/admin/contract", {
            method: "GET",
            headers: {'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")}
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<DogovorType[]>) => {
                return data;
            });
    }

    public static addDogovor(dogovor: any): Promise<DogovorType> {
        return fetch(this.BASE + "/api/admin/contract", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")
            },
            body: JSON.stringify(dogovor, (_, v) => typeof v === 'bigint' ? v.toString() : v)
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<DogovorType>) => {
                return data;
            });
    }

    public static extendDogovor(id: BigInt): Promise<DogovorType> {
        return fetch(this.BASE + "/api/admin/contract/extend?id=" + id.toString(), {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")
            },
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<DogovorType>) => {
                return data;
            });
    }

    public static getFileByType(type: string): Promise<FileType[]> {
        return fetch(this.BASE + "/api/file/?type=" + type, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt")
            },
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<FileType[]>) => {
                return data;
            });
    }

    public static newRequestBlock(requestBlock: FormData): Promise<RequestBlockType> {
        return fetch(this.BASE + "/api/request/block", {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("jwt"),
            },
            body: requestBlock
        })
            .then((response) => {
                if (response.status === 401) {
                    sessionStorage.removeItem("me");
                    sessionStorage.removeItem("jwt");
                    window.location.href = '/';
                }
                return response.json();
            })
            .then((data: Promise<RequestBlockType>) => {
                return data;
            });
    }

    public static getWaterPublic(id: string): Promise<WaterPublicType> {
        return fetch(this.BASE + "/api/water/public?id=" + id, {
            method: "GET"
        })
            .then((response) => {
                return response.json();
            })
            .then((data: Promise<WaterPublicType>) => {
                return data;
            });
    }
}
