import React, {ReactElement, useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {REST} from "../../REST";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {WaterPublicType} from "../../types/WaterPublicType";

export function PublicPage(props: any): ReactElement {
    const {id} = useParams<string>();
    const [currentId, setCurrentId] = useState<string>();
    const id0 = useRef<HTMLInputElement>(null);
    const id1 = useRef<HTMLInputElement>(null);
    const id2 = useRef<HTMLInputElement>(null);
    const id3 = useRef<HTMLInputElement>(null);
    const id4 = useRef<HTMLInputElement>(null);
    const id5 = useRef<HTMLInputElement>(null);
    const id6 = useRef<HTMLInputElement>(null);
    const id7 = useRef<HTMLInputElement>(null);
    const id8 = useRef<HTMLInputElement>(null);
    const id9 = useRef<HTMLInputElement>(null);

    const [info, setInfo] = useState<WaterPublicType>();

    useEffect(() => {
        REST.getWaterPublic(id!).then(i => {
            setInfo(i);
        });
    }, [id]);

    const handle = () => {
        const x = id0.current!.value +
            id1.current!.value +
            id2.current!.value +
            id3.current!.value +
            id4.current!.value +
            id5.current!.value +
            id6.current!.value +
            id7.current!.value +
            id8.current!.value +
            id9.current!.value;
        setCurrentId(x);
    }

    if (id === undefined) {
        return (
            <div className={"ModalBackground"}>
                <div className={"Modal"}>
                    <div className={"ModalHeader"} style={{justifyContent: "space-between"}}>
                        Публичная страница МОРРЕЧФЛОТ
                    </div>
                    <div className={"ModalBody"}>

                        <div>
                            <div className="input-group input-group-sm mb-1">
                                <span className="input-group-text">Номер процедуры досмотра</span>
                                <input className="form-control nospin" type={"number"} min={0} max={9} step={1}
                                       maxLength={1}
                                       onChange={() => id0.current!.value.length === 1 ? id1.current!.focus() : ""}
                                       defaultValue={""}
                                       placeholder={"9"} ref={id0}/>
                                <input className="form-control nospin" type={"number"} min={0} max={9} step={1}
                                       maxLength={1}
                                       onChange={() => id1.current!.value.length === 1 ? id2.current!.focus() : ""}
                                       defaultValue={""}
                                       placeholder={"9"} ref={id1}/>
                                <input className="form-control nospin" type={"number"} min={0} max={9} step={1}
                                       maxLength={1}
                                       onChange={() => id2.current!.value.length === 1 ? id3.current!.focus() : ""}
                                       defaultValue={""}
                                       placeholder={"9"} ref={id2}/>
                                <input className="form-control nospin" type={"number"} min={0} max={9} step={1}
                                       maxLength={1}
                                       onChange={() => id3.current!.value.length === 1 ? id4.current!.focus() : ""}
                                       defaultValue={""}
                                       placeholder={"9"} ref={id3}/>
                                <input className="form-control nospin" type={"number"} min={0} max={9} step={1}
                                       maxLength={1}
                                       onChange={() => id4.current!.value.length === 1 ? id5.current!.focus() : ""}
                                       defaultValue={""}
                                       placeholder={"9"} ref={id4}/>
                                <span
                                    className="input-group-text fw-bold">&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <input className="form-control nospin" type={"number"} min={0} max={9} step={1}
                                       maxLength={1}
                                       onChange={() => id5.current!.value.length === 1 ? id6.current!.focus() : ""}
                                       defaultValue={""}
                                       placeholder={"9"} ref={id5}/>
                                <input className="form-control nospin" type={"number"} min={0} max={9} step={1}
                                       maxLength={1}
                                       onChange={() => id6.current!.value.length === 1 ? id7.current!.focus() : ""}
                                       defaultValue={""}
                                       placeholder={"9"} ref={id6}/>
                                <span
                                    className="input-group-text fw-bold">&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <input className="form-control nospin" type={"number"} min={0} max={9} step={1}
                                       maxLength={1}
                                       onChange={() => id7.current!.value.length === 1 ? id8.current!.focus() : ""}
                                       defaultValue={""}
                                       placeholder={"9"} ref={id7}/>
                                <input className="form-control nospin" type={"number"} min={0} max={9} step={1}
                                       maxLength={1}
                                       onChange={() => id8.current!.value.length === 1 ? id9.current!.focus() : ""}
                                       defaultValue={""}
                                       placeholder={"9"} ref={id8}/>
                                <input className="form-control nospin" type={"number"} min={0} max={9} step={1}
                                       maxLength={1} onChange={() => id9.current!.value.length === 1 ? handle() : ""}
                                       defaultValue={""}
                                       placeholder={"9"} ref={id9}/>
                            </div>
                        </div>

                    </div>
                    <div className={"ModalFooter"}>
                        <div className="Button Primary"
                             onClick={() => {
                                 window.location.href = "/water/public/" + currentId!;
                             }}>
                            <FontAwesomeIcon icon={faEye}/>&nbsp;Показать информацию
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={"ModalBackground"}>
            <div className={"Modal"}>
                <div className={"ModalHeader"} style={{justifyContent: "space-between"}}>
                    Публичная страница МОРРЕЧФЛОТ
                </div>
                <div className={"ModalBody"}>

                    <div>
                        <div className="input-group input-group-sm mb-1">
                            <span className="input-group-text">Номер процедуры досмотра</span>
                            <div className="form-control">{info?.id}</div>
                        </div>

                        <div className="input-group input-group-sm mb-1">
                            <span className="input-group-text">Объект транспортной инфраструктуры</span>
                            <div className="form-control">{"-"}</div>
                        </div>

                        <div className="input-group input-group-sm mb-1">
                            <span className="input-group-text">Транспортное средство</span>
                            <div className="form-control" id="otb">{info?.otb}</div>
                        </div>

                        <div className="input-group input-group-sm mb-1">
                            <span className="input-group-text">Подразделение транспортной безопасности</span>
                            <div className="form-control" id="executor">{info?.executor}</div>
                        </div>

                        <div className="input-group input-group-sm mb-1">
                            <span className="input-group-text">Работника досмотра</span>
                            <div className="form-control" id="finalExecutor">{info?.finalExecutor}</div>
                        </div>

                        <div className="input-group input-group-sm mb-1">
                            <span className="input-group-text">Груз</span>
                            <div className="form-control" id="cargo">{info?.cargo}</div>
                        </div>

                        <div className="input-group input-group-sm mb-1">
                            <span className="input-group-text">Время погрузки</span>
                            <div className="form-control"
                                 id="loadtime">{(info?.startLoadTime ?? "") + " - " + (info?.endLoadTime ?? "")}</div>
                        </div>

                        <div className="input-group input-group-sm mb-1">
                            <span
                                className="input-group-text">При осуществлении досмотра обнаружено (не обнаружено)</span>
                            <div className="form-control">{"-"}</div>
                        </div>
                    </div>

                </div>
                <div className={"ModalFooter"}>

                </div>
            </div>
        </div>
    )
}